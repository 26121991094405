.maintenance-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f9f9f9;
  text-align: center;
  font-family: Arial, sans-serif;
}

.maintenance-content {
  background: white;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.gear-image {
  width: 110px;
  max-width: 110px;
  height: auto;
    right: 10px;
      position: relative;
}

footer {
  margin-top: 20px;
  font-size: 14px;
  color: #888;
}

.contact-info {
  position: fixed;
  bottom: 20px;
  right: 20px;
  font-size: 14px;
  color: #666;
  padding: 8px 12px;
  border-radius: 5px;
}
.logo {
  position: fixed;
  top: 100px;
  align-items: center;
  font-size: 14px;
  padding: 8px 12px;
}
.center-text {
  position: absolute;
  top: 110%;
  left: 50%;
  transform: translate(-50%, -50%);
}
